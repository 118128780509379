import * as Sentry from '@sentry/vue'

export default (app) => {
  Sentry.init({
    // @ts-ignore
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'staging',
    app,
    dsn: 'https://ffd0edc43174d8f742711d75faa52a09@o4508121914212352.ingest.de.sentry.io/4508404002914384',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 0.2,
    profilesSampleRate: 1.0,
  })
}
