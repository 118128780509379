export default () => {
  if (typeof window === 'undefined') return

  const originalPushState = window.history.pushState

  function urlChange() {
    const event = new Event('urlchange')
    window.dispatchEvent(event)
  }

  window.history.pushState = function () {
    originalPushState.apply(this, arguments)
    urlChange()
  }
}
