const showMermaidDiagram = async () => {
  const mermaidElements = await new Promise<HTMLCollectionOf<Element> | null>(
    (resolve) => {
      if (typeof MutationObserver === 'undefined') resolve(null)

      const observer = new MutationObserver(() => {
        const mermaidElements = document.getElementsByClassName('mermaid')

        if (mermaidElements.length > 0) {
          observer.disconnect()
          resolve(mermaidElements)
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      })

      setTimeout(() => {
        observer.disconnect()
        resolve(null)
      }, 600)
    }
  )

  if (!mermaidElements) {
    return
  }

  for (let i = 0; i < mermaidElements.length; i++) {
    // Display the mermaid element
    mermaidElements[i].classList.add('display')

    // Fade in the diagram
    setTimeout(function () {
      mermaidElements[i].classList.add('show')
    }, 400)
  }
}

export default () => {
  if (typeof window === 'undefined') return

  showMermaidDiagram()

  window.addEventListener('urlchange', function () {
    showMermaidDiagram()
  })
}
