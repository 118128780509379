import {
  cookieTools,
  addBar,
  hideBar,
  addGaScripts,
  getBaseDomain,
} from '@streeva/very-simple-cookie-consent'

export default () => {
  if (typeof window === 'undefined') return

  // @ts-ignore
  const TAG = import.meta.env.VITE_GOOGLE_GTAG
  const privacyPolicyLink = 'https://www.swiftaid.co.uk/legal/cookies/'
  const ct = cookieTools.init({
    domain: getBaseDomain(),
    expires: 365,
    sameSite: 'Lax',
  })

  /**
   * Approve consent handler
   *
   * @param e
   */
  const approveHandler = (e) => {
    e.preventDefault()
    hideBar()
    ct.approve()
    // load GA script
    addGaScripts(TAG)
  }
  /**
   * Decline consent handler
   *
   * @param e
   */
  const declineHandler = (e) => {
    e.preventDefault()
    hideBar()
    ct.decline()
  }

  // Check has approved or declined cookies
  if (!ct.hasMadeChoice()) {
    // Show consent bar
    addBar(privacyPolicyLink, approveHandler, declineHandler)
    return
  }

  // Check has approved cookies
  if (ct.hasConsent()) {
    // load GA scripts
    addGaScripts(TAG)
    return
  }
}
