import DefaultTheme from 'vitepress/theme'
import SwiftaidHomeLayout from './SwiftaidHomeLayout/index.vue'
import sentryInit from './sentry-init'
import cookieConsentSetup from './cookie-consent-setup'
import addUrlChangeEvent from './add-urlchange-event'
import showMermaidDiagram from './show-mermaid-diagram'
import './custom.css'

export default {
  extends: DefaultTheme,
  enhanceApp({ app }) {
    app.component('SwiftaidHomeLayout', SwiftaidHomeLayout)

    sentryInit(app)
    cookieConsentSetup()
    addUrlChangeEvent()
    showMermaidDiagram()
  },
}
