<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import VPFeatures from '../../../node_modules/vitepress/dist/client/theme-default/components/VPFeatures.vue'
import VPButton from '../../../node_modules/vitepress/dist/client/theme-default/components/VPButton.vue'
import { useData } from 'vitepress'
import Footer from './Footer.vue'

const { site, frontmatter: fm } = useData()

onMounted(() => {
  document.documentElement.classList.add('home')
})

onUnmounted(() => {
  document.documentElement.classList.remove('home')
})
</script>

<template>
  <div class="VPContent is-home" id="VPContent">
    <div class="VPHome">
      <div class="VPHero VPHomeHero">
        <div class="container">
          <div v-if="fm.hero.image" class="image">
            <img
              class="image-src"
              :src="fm.hero.image.src"
              :alt="fm.hero.image.alt"
            />
          </div>

          <div class="main">
            <h1 class="title">{{ fm.hero.title }}</h1>
            <p class="tagline">{{ fm.hero.tagline }}</p>

            <div v-if="fm.hero.actions" class="actions">
              <div
                v-for="action in fm.hero.actions"
                :key="action.link"
                class="action"
              >
                <VPButton
                  tag="a"
                  size="medium"
                  :theme="action.theme"
                  :text="action.text"
                  :href="action.link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="home-section">
        <VPFeatures
          v-if="fm.features"
          class="VPHomeFeatures"
          :features="fm.features"
        />
      </section>

      <section v-if="fm.developerFeatures" class="home-section">
        <div class="container">
          <div class="developer-features">
            <div v-for="feature in fm.developerFeatures" class="item">
              <h2 class="title">{{ feature.title }}</h2>
              <p class="details" v-html="feature.details"></p>
            </div>
          </div>
        </div>
      </section>

      <section class="home-section">
        <VPFeatures
          v-if="fm.environmentFeatures"
          class="VPHomeEnvironmentFeatures"
          :features="fm.environmentFeatures"
        />
      </section>

      <Footer :fm="fm" />
    </div>
  </div>
</template>

<style>
.home.dark .VPImage {
  filter: drop-shadow(0 0 10px rgba(32, 84, 196, 0.2)) invert(0.9)
    hue-rotate(192deg);
}

.home .VPHomeHero .VPButton.brand {
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--vp-font-header-base);
  color: var(--vp-c-bg);
}

.home .VPFeatures.VPHomeFeatures .box {
  align-items: center;
}
</style>

<style scoped>
.VPContent.is-home {
  padding-top: 0;
}

.VPHero {
  margin-top: calc(
    (var(--vp-nav-height) + var(--vp-layout-top-height, 0px)) * -1
  );
  padding: calc(var(--vp-nav-height) + var(--vp-layout-top-height, 0px) + 48px)
    24px 48px;
}

.home-section {
  margin-top: 96px;
  margin-bottom: 96px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
}

.image {
  position: relative;
}

.dark .image:before {
  content: '';
  display: block;
  padding-top: 56.25%;
  width: 100%;
  height: 100%;
  background-color: hsl(230deg 79% 31%);
  position: absolute;
  top: -200px;
  left: 0;
  z-index: -1;
  filter: blur(120px) opacity(0.2);
}

.image-src {
  max-width: 960px;
  width: 100%;
  margin: auto;
}

.dark .image-src {
  filter: drop-shadow(0 20px 100px rgba(23, 21, 150, 0.7)) invert(0.9)
    hue-rotate(180deg) saturate(1.5) contrast(1.1);
}

.title {
  letter-spacing: -0.4px;
  line-height: 40px;
  font-size: 24px;
  font-weight: 700;
  white-space: pre-wrap;
  text-align: center;
}

.tagline {
  padding-top: 8px;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 500;
  white-space: pre-wrap;
  color: var(--vp-c-text-2);
  text-align: center;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  padding-top: 24px;
}

.developer-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.developer-features .item {
  margin-bottom: 48px;
}

.developer-features .item:last-child {
  margin-bottom: 0;
}

.developer-features .title {
  line-height: 1.6;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.developer-features .details {
  padding-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: var(--vp-c-text-2);
  text-align: center;
  max-width: 80%;
  margin: auto;
}

@media (min-width: 640px) {
  .VPHero {
    padding: calc(
        var(--vp-nav-height) + var(--vp-layout-top-height, 0px) + 80px
      )
      48px 64px;
  }

  .name,
  .title {
    line-height: 1.6;
    font-size: 28px;
  }

  .tagline {
    padding-top: 12px;
    line-height: 1.6;
    font-size: 20px;
    max-width: 60%;
  }

  .actions {
    padding-top: 32px;
  }
}

@media (min-width: 960px) {
  .VPHero {
    padding: calc(
        var(--vp-nav-height) + var(--vp-layout-top-height, 0px) + 80px
      )
      64px 64px;
  }

  .name,
  .title {
    line-height: 1.1;
    font-size: 46px;
  }

  .tagline {
    line-height: 1.6;
    font-size: 24px;
  }
}
</style>
