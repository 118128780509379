<script setup lang="ts">
import VPSocialLinks from '../../../node_modules/vitepress/dist/client/theme-default/components/VPSocialLinks.vue'

defineProps({
  fm: {
    type: Object,
    required: true,
  },
})

const currentYear = new Date().getFullYear()
</script>

<template>
  <footer v-if="fm.footer">
    <div class="container">
      <div class="mascot">
        <img
          src="/swifty-and-heart.png"
          alt="Swiftaid mascot Swifty throwing a heart in the air"
          width="100%"
        />
      </div>

      <div class="footer">
        <div class="logo">
          <img src="/favicon.png" alt="Swiftaid logo" />
          Swiftaid
        </div>

        <div class="text">
          <p>
            copyright © {{ currentYear }}
            <a v-if="fm.footer.companyLink" :href="fm.footer.companyLink">
              {{ fm.footer.companyLinkText }}
            </a>
            {{ fm.footer.registrationText }}
          </p>
          <VPSocialLinks
            v-if="fm.footer.socialLinks"
            class="social-links"
            :links="fm.footer.socialLinks"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  margin-top: 48px;
}

.mascot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  width: 60px;
  padding-bottom: 20px;
}

.mascot:after {
  position: absolute;
  left: 0;
  bottom: 15px;
  content: '';
  display: block;
  width: 100%;
  height: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.4;
}

.dark .mascot:after {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.footer {
  padding: 0 0 40px;
  background-color: var(--vp-c-bg);
  text-align: center;
  font-size: 14px;
  color: var(--vp-c-text-2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  font-family: var(--vp-font-header-base);
  font-size: 24px;
  font-weight: 600;
  color: var(--vp-c-text-1);
}

.logo img {
  width: 29px;
  height: 29px;
  margin-right: 8px;
}

.text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

footer .text p:first-child {
  margin-right: 20px;
}
</style>
